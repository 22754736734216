import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import ContextConsumer, { ContextProviderComponent } from '../utils/context'

import Header from '../components/header'
import Footer from '../components/footer'
import Video from '../components/video'
import favicon from '../assets/images/favicon.png';
import ogImage from '../assets/images/og-image.jpg'
import '../assets/scss/main.scss'

const Layout = ({ children, location }) => {

  const [pathName, setPathName] = useState("")
  
  useEffect(() => {
    setPathName(window.location.pathname)
  })

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
          allWpService(sort: {menuOrder: ASC}) {
            edges {
              node {
                title
                slug
              }
            }
          }
          wp {
            siteSettings {
              acf {
                footerContent
                newsletter
              }
            }
          }
        }
      `}
      render={(data) => {

        let context = (props) => {
          let video = props.data.image ? <Video {...props.data} /> : null
          let light = location.pathname === '/' || video
          if (children.props.pageContext.type !== 'single-work' && video !== null) {
            props.set({ image: null })
          }
          return (<>
            <Header siteTitle={data.site.siteMetadata.title} services={data.allWpService} light={light} path={pathName} />
            { video }
          </>)
        }

        return (
          <ContextProviderComponent>
            <Helmet
              defaultTitle={`Ramus`} titleTemplate={`%s`}
              title={`Ramus`}
              meta={[
                { name: 'description', content: '' },
                { name: 'keywords', content: '' },
                { name: 'google-site-verification', content: 'ITpD-WlKV67m_uJz4bQo28aQhjg0EaU6zAutrwislg4' }
              ]}
            >
              <html lang="en" />
              <link rel="icon" href={favicon} sizes="32x32" type="image/png" />
              <meta property="og:image:width" content="1200" />
              <meta property="og:image:height" content="630" />
              <meta property="og:image" content={ogImage} />
              <meta property="og:image:url" content={ogImage} />
              <meta property="og:type" content="website" />
              <meta property="og:title" content="Ramus - Our work is luminous art." />
              <meta property="og:description" content="Design studio led by Bruce Ramus. Creating luminous art and integrated public precincts using light, sculpture, digital media and technology." />
              <meta property="og:site_name" content="Ramus" />
              <meta property="og:url" content={typeof window !== 'undefined' && window.location} />
              <meta name="twitter:card" content="summary" />
              <meta name="twitter:description" content="Design studio led by Bruce Ramus. Creating luminous art and integrated public precincts using light, sculpture, digital media and technology." />
              <meta name="twitter:title" content="Ramus - Our work is luminous art." />
              <script>
                {`var _hmt = _hmt || [];
                  (function() {
                    var hm = document.createElement("script");
                    hm.src = "https://hm.baidu.com/hm.js?f83f670a1ae45e6540f4f25aa59a0aa0";
                    var s = document.getElementsByTagName("script")[0];
                    s.parentNode.insertBefore(hm, s);
                  })();`}
              </script>
            </Helmet>
            <ContextConsumer>{ context }</ContextConsumer>
            <main className="site-wrapper">
              { children }
            </main>
            { location.pathname !== '/' && <Footer footerSettings={data.wp.siteSettings.acf} /> }
          </ContextProviderComponent>
        )
      }}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
